import styled from "styled-components";

export const SHero = styled.section`
  height: 75vh;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;

  @media (max-width: 800px) {
    height: 60vh;
  }
`;

export const SGridGallery = styled.section`
  display: grid;
  padding: 50px 30px;
  gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;

  background: rgb(42, 82, 190);

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`;

export const SGalleryImg = styled.div`
  height: 325px;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;