import languages from "./languages";
import * as en from '../assets/locales/en/translation.json';
import * as it from '../assets/locales/it/translation.json';
import * as es from '../assets/locales/es/translation.json';

const i18InitConfig = {
  supportedLngs: languages,
  fallbackLng: "it",
  detection: {
    order: ['path', 'cookie', 'htmlTag'],
    caches: ['cookie'],
  },
  resources: {
    it: { translation: it },
    en: { translation: en },
    es: { translation: es }
  },
  react: { useSuspense: true }
}

export default i18InitConfig;