import styled from "styled-components";

export const SGridGalleryContainer = styled.div`
  width: 100%;
  height: auto;

  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
`;

export const SGGLg = styled.div`
  height: 100%;

  grid-column: span 4;

  @media (max-width: 768px) {
    grid-column: span 6;
  }
`;

export const SGGSm = styled.div`
  height: 100%;

  grid-column: span 2;

  @media (max-width: 768px) {
    grid-column: span 6;
  }
`;

export const SGGSmDiv = styled.div`
  width: 100%;
  height: 150px;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;

export const SGGLgImg = styled.div`
  width: 100%;
  height: 310px;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;

export const SGGSmImg = styled.div`
  width: 100%;
  height: 150px;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;