import React from 'react';
import { useTranslation } from "react-i18next";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';
import {
  SHero,
  SGridGallery,
  SGalleryImg
} from './styles';
import { SHrAuto } from '../../styles/hrStyles'
import { SDivider10, SDivider100, SDivider20, SDivider30, SDivider50 } from '../../styles/dividerStyles';
import { GoLocation } from "react-icons/go";

const carouselImg0 = 'url(/assets/img/stazzone/room_1.webp)';
const carouselImg1 = 'url(/assets/img/stazzone/external_1.webp)';
const carouselImg2 = 'url(/assets/img/stazzone/kitchen_1.webp)';
const carouselImg3 = 'url(/assets/img/stazzone/kitchen_3.webp)';
const carouselImg4 = 'url(/assets/img/stazzone/bedroom_1.webp)';
const carouselImg5 = 'url(/assets/img/stazzone/bedroom_4.webp)';

const galleryImg0 = 'url(/assets/img/stazzone/room_1.webp)';
const galleryImg1 = 'url(/assets/img/stazzone/bedroom_1.webp)';
const galleryImg2 = 'url(/assets/img/stazzone/bedroom_2.webp)';
const galleryImg3 = 'url(/assets/img/stazzone/bedroom_3.webp)';
const galleryImg4 = 'url(/assets/img/stazzone/bedroom_4.webp)';
const galleryImg5 = 'url(/assets/img/stazzone/bedroom_5.webp)';
const galleryImg6 = 'url(/assets/img/stazzone/kitchen_1.webp)';
const galleryImg7 = 'url(/assets/img/stazzone/kitchen_2.webp)';
const galleryImg8 = 'url(/assets/img/stazzone/kitchen_3.webp)';
const galleryImg9 = 'url(/assets/img/stazzone/external_1.webp)';
const galleryImg10 = 'url(/assets/img/stazzone/external_2.webp)';
const galleryImg11 = 'url(/assets/img/stazzone/external_3.webp)';
const galleryImg12 = 'url(/assets/img/stazzone/external_4.webp)';
const galleryImg13 = 'url(/assets/img/stazzone/bathroom_1.webp)';
const galleryImg14 = 'url(/assets/img/stazzone/bathroom_2.webp)';

export default function Apartment() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('stazzone-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('stazzone-description')}
        keyword={t('stazzone-keyword')}
        path='stazzone'
      />
      <Header/>
      <Hero/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Hero = () => {
  return (
    <Carousel infiniteLoop={true} showIndicators={true} showThumbs={false} showStatus={false}>
      <div>
        <SHero style={{backgroundImage: `${carouselImg0}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg1}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg2}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg3}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg4}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg5}`}}/>
      </div>
    </Carousel>
  )
}

const Main = () => {
  return (
    <main>
      <Intro/>
      <Divider/>
      <Info/>
      <Divider/>
      <Geolocation/>
      <Divider/>
      <Gallery/>
    </main>
  )
}

const Intro = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-center">
      <h1>{t('stazzone-intro-1')}</h1>
      <SDivider20/>
      <p>
        {t('stazzone-intro-2')}<br/>
        {t('stazzone-intro-3')}
      </p>
    </div>
  )
}

const Info = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="container text-center">
        <h2 className="text-center">{t('info')}</h2>
        <SDivider20/>

        <div className='row'>
          <div className='col-md-4 col-sm-12'>
            <h3>{t('housing')}</h3>
            <SDivider10/>
            {t('stazzone-housing-1')}
            <SDivider10/>
            {t('stazzone-housing-2')}
            <SDivider10/>
            {t('stazzone-housing-3')}
            <SDivider10/>
            {t('stazzone-housing-4')}
            <SDivider10/>
            {t('stazzone-housing-5')}
            <SDivider10/>
            {t('stazzone-housing-6')}
          </div>

          <div className='col-md-4 col-sm-12'>
            <h3>{t('accessories')}</h3>
            <SDivider10/>
            {t('stazzone-accessories-1')}
            <SDivider10/>
            {t('stazzone-accessories-2')}
          </div>

          <div className='col-md-4 col-sm-12'>
            <h3>{t('rules')}</h3>
            <SDivider10/>
            {t('stazzone-rules-1')}
          </div>
        </div>
      </div>
    </div>
  )
}

const Geolocation = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-center">
      <h2>{t('geolocation')}</h2>
      <SDivider20/>

      <strong>
        <GoLocation style={{fontSize: "20px", marginRight: "8px"}}/>
        <a href="https://goo.gl/maps/9eyLN5TVjc7ppKU4A" target="_blank" rel="noopener noreferrer">
          Via Lido Esperando, 102, 92019 Sciacca AG
        </a>
      </strong>
      <SDivider30/>

      <iframe title={`Location appartamento a Sciacca on google maps`} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d791.27928713967!2d13.068281469655952!3d37.50515506214996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x131a46a7c0eb29d3%3A0xca206fd439e10386!2sVia%20Lido%20Esperando%2C%20102%2C%2092019%20Sciacca%20AG!5e0!3m2!1sit!2sit!4v1691171321120!5m2!1sit!2sit" style={{border: "0", minHeight: "350px", height: "100%", width: "100%"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
    </div>
  )
}

const Gallery = () => {
  return (
    <SGridGallery>
      <SGalleryImg style={{backgroundImage: `${galleryImg0}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg1}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg2}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg3}`}}/>

      <SGalleryImg style={{backgroundImage: `${galleryImg4}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg5}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg6}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg7}`}}/>

      <SGalleryImg style={{backgroundImage: `${galleryImg8}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg9}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg10}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg11}`}}/>

      <SGalleryImg style={{backgroundImage: `${galleryImg12}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg13}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg14}`}}/>
    </SGridGallery>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider50/>
      <SHrAuto/>
      <SDivider50/>
    </>
  )
}