import styled, { keyframes } from "styled-components";

export const SHero = styled.section`
  height: 70vh;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;

  @media (max-width: 800px) {
    height: 60vh;
  }
`;

export const SH1 = styled.h1`
  font-size: 4rem;
  line-height: 4rem
`;

export const SSubTitle = styled.div`
  font-family: 'Bunya-Light_PERSONAL';
  font-size: 1.3rem;
  line-height: 1.3rem
`;

export const SH2 = styled.h2`
  font-size: 2.5rem;
  line-height: 3rem
`;

export const SGridCard = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 990px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const SCard = styled.div`
  margin: 10px 0px;
  border-radius: 28px;
  padding: 15px;

  background-color: rgb(42, 82, 190);

  animation: ${fadeIn} 0.9s;
  -webkit-animation: ${fadeIn} 0.9s;
  -moz-animation: ${fadeIn} 0.9s;
  -o-animation: ${fadeIn} 0.9s;
  -ms-animation: ${fadeIn} 0.9s;
`;

export const SCardTitle = styled.h3`
  padding: 15px;

  overflow: hidden;
  font-weight: bold;
  font-size: 30px;
  color: #FFF;
`;

export const SCardBody = styled.p`
  padding-bottom: 10px;
  color: #FFF;
`;

export const SParallax = styled.div`
  height: 450px;
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @supports (-webkit-touch-callout: none) {
    background-attachment: inherit;
  }
`;

export const SSideContainerMd = styled.div`
  width: 100%;
  min-height: 350px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0px;

  @media (max-width: 768px) {
  }
`;

export const SSideMd = styled.div`
  height: 100%;

  grid-column: span 1;

  @media (max-width: 768px) {
    height: 300px;
    grid-column: span 2;
  }
`;

export const SSideImgMd = styled.div`
  height: 100%;

  grid-column: span 1;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;

  @media (max-width: 768px) {
    height: 300px;
    grid-column: span 2;
  }
`;