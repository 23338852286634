import React from 'react';
import { useTranslation } from "react-i18next";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';
import {
  SHero,
  SGridGallery,
  SGalleryImg
} from './styles';
import { SHrAuto } from '../../styles/hrStyles'
import { SDivider10, SDivider100, SDivider20, SDivider30, SDivider50 } from '../../styles/dividerStyles';
import { GoLocation } from "react-icons/go";
import { SGridGalleryContainer, SGGLg, SGGSm, SGGSmDiv, SGGLgImg, SGGSmImg } from '../../styles/gridStyle';

const backgroundColor = `rgb(42, 82, 190)`;

const urlApartmentA = `https://www.airbnb.it/rooms/31289554?adults=1&category_tag=Tag%3A8678&enable_m3_private_room=true&photo_id=649000291&check_in=2023-12-22&source_impression_id=p3_1692637498_42Te2qBJ1FnuhUlm&previous_page_section_name=1000&federated_search_id=547f5296-1b36-4a08-9fe8-cdb6d59e9bb4&guests=1&check_out=2023-12-23&enable_auto_translate=false&_set_bev_on_new_domain=1692636579_M2EzYzk5ZjI1MDc3`;
const img1 = `url('/assets/img/new_york/apartment_1/apartment_1-1.webp')`;
const img2 = `url('/assets/img/new_york/apartment_1/apartment_1-2.webp')`;
const img3 = `url('/assets/img/new_york/apartment_1/apartment_1-3.webp')`;
const img4 = `url('/assets/img/new_york/apartment_1/apartment_1-4.webp')`;
const img5 = `url('/assets/img/new_york/apartment_1/apartment_1-5.webp')`;
const img6 = `url('/assets/img/new_york/apartment_1/apartment_1-6.webp')`;
const img7 = `url('/assets/img/new_york/apartment_1/apartment_1-7.webp')`;
const img8 = `url('/assets/img/new_york/apartment_1/apartment_1-8.webp')`;
const img9 = `url('/assets/img/new_york/apartment_1/apartment_1-9.webp')`;
const img10 = `url('/assets/img/new_york/apartment_1/apartment_1-10.webp')`;
const img11 = `url('/assets/img/new_york/apartment_1/apartment_1-11.webp')`;

const urlApartmentB = `https://www.airbnb.it/rooms/31285088?adults=1&category_tag=Tag%3A8678&children=0&enable_m3_private_room=true&infants=0&pets=0&photo_id=648866227&check_in=2023-10-22&check_out=2023-10-27&source_impression_id=p3_1692641764_bt5VyzyhGrKqrdYz&previous_page_section_name=1000&federated_search_id=84d4ce7f-08ce-4431-bc94-3c854f161920`;
const imgb1 = `url('/assets/img/new_york/apartment_2/apartment_2-1.webp')`;
const imgb2 = `url('/assets/img/new_york/apartment_2/apartment_2-2.webp')`;
const imgb3 = `url('/assets/img/new_york/apartment_2/apartment_2-3.webp')`;
const imgb4 = `url('/assets/img/new_york/apartment_2/apartment_2-4.webp')`;
const imgb5 = `url('/assets/img/new_york/apartment_2/apartment_2-5.webp')`;

const urlApartmentC = `https://www.airbnb.it/rooms/3719792?adults=1&category_tag=Tag%3A8678&children=0&enable_m3_private_room=true&infants=0&pets=0&photo_id=29231128&check_in=2023-10-08&check_out=2023-10-13&source_impression_id=p3_1692641764_KX90PV9ifT8f%2FXCh&previous_page_section_name=1000&federated_search_id=84d4ce7f-08ce-4431-bc94-3c854f161920`;
const imgc1 = `url('/assets/img/new_york/apartment_3/apartment_3-1.webp')`;
const imgc2 = `url('/assets/img/new_york/apartment_3/apartment_3-2.webp')`;
const imgc3 = `url('/assets/img/new_york/apartment_3/apartment_3-3.webp')`;
const imgc4 = `url('/assets/img/new_york/apartment_3/apartment_3-4.webp')`;
const imgc5 = `url('/assets/img/new_york/apartment_3/apartment_3-5.webp')`;
const imgc6 = `url('/assets/img/new_york/apartment_3/apartment_3-6.webp')`;
const imgc7 = `url('/assets/img/new_york/apartment_3/apartment_3-7.webp')`;
const imgc8 = `url('/assets/img/new_york/apartment_3/apartment_3-8.webp')`;
const imgc9 = `url('/assets/img/new_york/apartment_3/apartment_3-9.webp')`;
const imgc15 = `url('/assets/img/new_york/apartment_3/apartment_3-11.webp')`;
const imgc16 = `url('/assets/img/new_york/apartment_3/apartment_3-12.webp')`;

const urlApartmentD = `https://www.airbnb.it/rooms/3849488?adults=1&category_tag=Tag%3A8678&children=0&enable_m3_private_room=true&infants=0&pets=0&photo_id=30021356&check_in=2023-10-02&check_out=2023-10-07&source_impression_id=p3_1692641764_esyzBQLQskQDuUeI&previous_page_section_name=1000&federated_search_id=84d4ce7f-08ce-4431-bc94-3c854f161920`;
const imgd1 = `url('/assets/img/new_york/apartment_4/apartment_4-1.webp')`;
const imgd2 = `url('/assets/img/new_york/apartment_4/apartment_4-2.webp')`;
const imgd3 = `url('/assets/img/new_york/apartment_4/apartment_4-3.webp')`;
const imgd4 = `url('/assets/img/new_york/apartment_4/apartment_4-4.webp')`;
const imgd5 = `url('/assets/img/new_york/apartment_4/apartment_4-5.webp')`;

export default function NewYork() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('ny_apartment-title')}
        description={t('ny_apartment-description')}
        keyword={t('ny_apartment-keyword')}
        path='ny_apartment'
      />
      <Header/>
      <Hero/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Hero = () => {
  return (
    <Carousel infiniteLoop={true} showIndicators={true} showThumbs={false} showStatus={false}>
      <div>
        <SHero style={{backgroundImage: `${img1}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${img2}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${img3}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${img4}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${img5}`}}/>
      </div>
    </Carousel>
  )
}

const Main = () => {
  return (
    <main>
      <Intro/>
      <Divider/>
      <Info/>
      <Divider/>
      <Geolocation/>
      <Divider/>
      <Price/>
      <Divider/>
      <Gallery/>
    </main>
  )
}

const Intro = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-center">
      <h1>{t('ny_apartment-intro-1')}</h1>
      <SDivider20/>
      <p>
        {t('ny_apartment-intro-2')}
        <br/>
        <br/>
        {t('ny_apartment-intro-3')}
      </p>
    </div>
  )
}

const Info = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="container text-center">
        <h2 className="text-center">{t('info')}</h2>
        <SDivider20/>

        <div className='row'>
          <div className='col-lg-4 col-md-4 col-sm-12'>
            <h3>{t('housing')}</h3>
            <SDivider10/>
            {t('ny_apartment-housing-2')}
            <SDivider10/>
            {t('ny_apartment-housing-3')}
            <SDivider10/>
            {t('ny_apartment-housing-4')}
            <SDivider10/>
            {t('ny_apartment-housing-5')}
          </div>

          <div className='col-lg-4 col-md-4 col-sm-12'>
            <h3>{t('accessories')}</h3>
            <SDivider10/>
            {t('ny_apartment-accessories-2')}
            <SDivider10/>
            {t('ny_apartment-accessories-3')}
            <SDivider10/>
            {t('ny_apartment-accessories-4')}
            <SDivider10/>
            {t('ny_apartment-accessories-5')}
            <SDivider10/>
            {t('ny_apartment-accessories-6')}
            <SDivider10/>
            {t('ny_apartment-accessories-7')}
            <SDivider10/>
            {t('ny_apartment-accessories-8')}
            <SDivider10/>
            {t('ny_apartment-accessories-9')}
          </div>

          <div className='col-lg-4 col-md-4 col-sm-12'>
            <h3>{t('rules')}</h3>
            <SDivider10/>
            <strong>{t('ny_apartment-rules-2')}</strong>
            <SDivider10/>
            <strong>{t('ny_apartment-rules-3')}</strong>
            <SDivider10/>
            {t('ny_apartment-rules-4')}
            <SDivider10/>
            {t('ny_apartment-rules-5')}
            <SDivider10/>
            {t('ny_apartment-rules-6')}
            <SDivider10/>
            {t('ny_apartment-rules-7')}
          </div>
        </div>
      </div>
    </div>
  )
}

const Geolocation = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-center">
      <h2>{t('geolocation')}</h2>
      <SDivider20/>
      <strong>
        <GoLocation style={{fontSize: "20px", marginRight: "8px"}}/>
        <a href={process.env.REACT_APP_GOOGLE_MAPS} target="_blank" rel="noopener noreferrer">
          136 Wardwell Rd, Mineola, NY 11501, Stati Uniti
        </a>
      </strong>

      <SDivider30/>

      <iframe title={`Location ${process.env.REACT_APP_APP_NAME} on google maps`} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d755.7533607947382!2d-73.65311563040537!3d40.73972971434294!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c27d52df4e59ff%3A0x5b0e75f94fd42b9f!2s136%20Wardwell%20Rd%2C%20Mineola%2C%20NY%2011501%2C%20Stati%20Uniti!5e0!3m2!1sit!2sit!4v1692720084473!5m2!1sit!2sit" style={{border: "0", minHeight: "400px", height: "100%", width: "100%"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
  )
}

const Price = () => {
  const { t } = useTranslation();

  return (
    <div className='container text-center'>
      <h3>{t('ny_apartment-booking-1')}</h3>
      <SDivider20/>

      <GridGallery
        href={urlApartmentA}
        imgOne={img1}
        imgTwo={img2}
        imgThree={img3}
        imgFour={img4}
        imgFive={img5}
      />

      <SDivider50/>

      <h3>{t('ny_apartment-booking-2')}</h3>
      <SDivider20/>

      <GridGallery
        href={urlApartmentB}
        imgOne={imgb1}
        imgTwo={imgb2}
        imgThree={imgb3}
        imgFour={imgb4}
        imgFive={imgb5}
      />

      <SDivider50/>

      <h3>{t('ny_apartment-booking-3')}</h3>
      <SDivider20/>

      <GridGallery
        href={urlApartmentC}
        imgOne={imgc1}
        imgTwo={imgc2}
        imgThree={imgc3}
        imgFour={imgc4}
        imgFive={imgc5}
      />

      <SDivider50/>

      <h3>{t('ny_apartment-booking-4')}</h3>
      <SDivider20/>

      <GridGallery
        href={urlApartmentD}
        imgOne={imgd1}
        imgTwo={imgd2}
        imgThree={imgd3}
        imgFour={imgd4}
        imgFive={imgd5}
      />
    </div>
  )
}

const Gallery = () => {
  return (
    <SGridGallery>
      <SGalleryImg style={{backgroundImage: `${img1}`}}/>
      <SGalleryImg style={{backgroundImage: `${imgb2}`}}/>
      <SGalleryImg style={{backgroundImage: `${img6}`}}/>
      <SGalleryImg style={{backgroundImage: `${img7}`}}/>

      <SGalleryImg style={{backgroundImage: `${img8}`}}/>
      <SGalleryImg style={{backgroundImage: `${img9}`}}/>
      <SGalleryImg style={{backgroundImage: `${img10}`}}/>
      <SGalleryImg style={{backgroundImage: `${img11}`}}/>

      <SGalleryImg style={{backgroundImage: `${imgc1}`}}/>
      <SGalleryImg style={{backgroundImage: `${imgd2}`}}/>
      <SGalleryImg style={{backgroundImage: `${imgc6}`}}/>
      <SGalleryImg style={{backgroundImage: `${imgc7}`}}/>

      <SGalleryImg style={{backgroundImage: `${imgc8}`}}/>
      <SGalleryImg style={{backgroundImage: `${imgc9}`}}/>
      <SGalleryImg style={{backgroundImage: `${imgc15}`}}/>
      <SGalleryImg style={{backgroundImage: `${imgc16}`}}/>
    </SGridGallery>
  )
}

const GridGallery = ({href, imgOne, imgTwo, imgThree, imgFour, imgFive}) => {
  const { t } = useTranslation();

  return (
    <SGridGalleryContainer>
      <SGGLg>
        <SGGLgImg style={{backgroundImage: `${imgOne}`}}/>
      </SGGLg>
      <SGGSm>
        <SGGSmImg style={{backgroundImage: `${imgTwo}`}}/>
        <SDivider10/>
        <SGGSmDiv style={{display: "table", background: `${backgroundColor}`}}>
        <a href={href} style={{padding: "5px", display: "table-cell", verticalAlign: "middle"}}>
          <div style={{ border: "2px solid #fff", width: "100%", height: "100%", padding: "5px", display: "table" }}>
            <div style={{display: "table-cell", verticalAlign: "middle", color: "#fff"}}>
              <h4 style={{color: "#fff"}}>{t('ny_apartment-booking-5')}</h4>
            </div>
          </div>
        </a>
        </SGGSmDiv>
      </SGGSm>
      <SGGSm>
        <SGGSmImg style={{backgroundImage: `${imgThree}`}}/>
      </SGGSm>
      <SGGSm>
        <SGGSmImg style={{backgroundImage: `${imgFour}`}}/>
      </SGGSm>
      <SGGSm>
        <SGGSmImg style={{backgroundImage: `${imgFive}`}}/>
      </SGGSm>
    </SGridGalleryContainer>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider50/>
      <SHrAuto/>
      <SDivider50/>
    </>
  )
}