import React from "react";
import AppRoutes from './AppRoutes';
import { GlobalStyle } from "./styles/globalStyles";
import { ThemeApp } from "./services/context/ThemeApp";
import ScrollToTop from './features/scrollToTop';
import CookieBanner from './components/cookieBanner/cookie-banner';

export default function App() {
  return (
    <ThemeApp>
      <GlobalStyle/>
      <ScrollToTop/>
      <AppRoutes/>
      <CookieBanner/>
    </ThemeApp>
  );
}