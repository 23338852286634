import styled from "styled-components";

export const STable = styled.table`
  border-spacing: 0px;
  width: 100%;
  text-align: left;
`;

export const STheadTh = styled.th`
  padding: 15px 6px;
  font-size: 0.95rem;
  font-family: "OpenSans-Bold";
  background-color: ${({ theme }) => theme.tableBgHead};
`;

export const STbodyTd = styled.td`
  padding: 15px 6px;
`;