import { Link } from "react-router-dom";
import styled from "styled-components";

const SMARTPHONE_WIDTH_MENU = "720px";

export const SHeaderBar = styled.header`
  display: grid;
  grid-template-areas: "logo nav";
  justify-content: space-between;

  width: 100%;

  background-color: ${({ theme }) => theme.headerBackground};

  @media (max-width: ${SMARTPHONE_WIDTH_MENU}) {
    grid-template-areas: "logo fabar" "nav nav";
  }
`;

export const SAreaLogo = styled.div`
  display: grid;
  grid-area: logo;
  grid-template-columns: repeat(4, auto);
  justify-items: start;

  line-height: 0 !important;
`;

export const SLogo = styled(Link)`
  line-height: 0 !important;
`;

export const SLogoImg = styled.img`
  height: 70px;

  @media (max-width: ${SMARTPHONE_WIDTH_MENU}) {
    height: 60px;
  }
`;

export const SMenuLangButton = styled.a`
  display: flex;
  border: none;
  padding: 4px 15px;

  background-color: transparent;
  align-items: center;
  color: ${({ theme }) => theme.headerColor};
  font-family: 'Myriad-Pro-Regular';
  font-size: 14px;
  cursor: pointer;
`;

export const SNavBar = styled.nav`
  display: grid;
  grid-area: nav;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  justify-items: center;
  justify-content: end;

  @media (max-width: ${SMARTPHONE_WIDTH_MENU}) {
    display: ${({ $isopen }) => (!$isopen ? `none` : `block`)};
    grid-template-rows: repeat(4, auto);
    grid-template-columns: none;

    max-height: 225px;
    overflow: auto;
    background-color: ${({ theme }) => theme.headerBackground};
    z-index: 2;
  }
`;

export const SFaBar = styled.button`
  display: none;
  grid-area: fabar;
  justify-self: end;

  border: none;
  padding: 10px;

  font-size: 25px;
  line-height: 0 !important;
  color: ${({ theme }) => theme.headerColor};
  background: transparent;
  cursor: pointer;

  @media (max-width: ${SMARTPHONE_WIDTH_MENU}) {
    display: inline;
  }
`;

export const SNavMenuLi = styled.li`
  display: inline-block;
  padding: 0px 10px;
  width: 100%;

  text-align: center;
  font-size: 17px;
`;

export const SNavMenuA = styled(Link)`
  display: block;
  padding: 17px 0px;

  font-family: 'Myriad-Pro-Regular';
  color: ${({ theme }) => theme.headerColor};

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: ${SMARTPHONE_WIDTH_MENU}) {
    width: 100%;
  }
`;