import React from "react";
import { Routes, Route } from "react-router-dom";
import i18next from 'i18next';
import { getLangCodeFromUrl } from "./features/getCodeLang";
import url from './config/url.json';

import Home from './pages/home';
import Contact from "./pages/contact";
import PolicyPrivacy from "./pages/policyPrivacy";
import Villa from "./pages/rent/villa";
import Apartment from "./pages/rent/apartment";
import NewYork from "./pages/rent/newYork";

export default function AppRoutes() {
  const languageCode = getLangCodeFromUrl();
  i18next.changeLanguage(languageCode);
  document.documentElement.lang = languageCode;

  return (
    <Routes>
      <Route index element={<Home/>} />

      { /* it */ }
      <Route path={url.it.home} element={<Home/>} />
      <Route path={url.it.contact} element={<Contact/>} />
      <Route path={url.it.privacy} element={<PolicyPrivacy/>} />
      <Route path={url.it.villa} element={<Villa/>} />
      <Route path={url.it.apartment} element={<Apartment/>} />
      <Route path={url.it.new_york} element={<NewYork/>} />

      { /* en */ }
      <Route path={url.en.home} element={<Home/>} />
      <Route path={url.en.contact} element={<Contact/>} />
      <Route path={url.en.privacy} element={<PolicyPrivacy/>} />
      <Route path={url.en.villa} element={<Villa/>} />
      <Route path={url.en.apartment} element={<Apartment/>} />
      <Route path={url.en.new_york} element={<NewYork/>} />

      { /* es */ }
      <Route path={url.es.home} element={<Home/>} />
      <Route path={url.es.contact} element={<Contact/>} />
      <Route path={url.es.privacy} element={<PolicyPrivacy/>} />
      <Route path={url.es.villa} element={<Villa/>} />
      <Route path={url.es.apartment} element={<Apartment/>} />
      <Route path={url.es.new_york} element={<NewYork/>} />

      { /* Missing page */ }
      <Route path="/*" element={<Home/>} />
    </Routes>
  );
};