import styled from "styled-components";

export const SHero = styled.section`
  height: 100vh;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;

export const SHeroH1 = styled.h1`
  margin-top: 20vh;
  border-top: 2px solid rgba(255, 255, 255, 0.3);
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);

  color: #fff;
  text-transform: uppercase;
  letter-spacing: 5px;
`;

export const SHeroH2 = styled.h2`
  color: #fff;
  font-size: 2rem;
`;

export const SMiniHero = styled.section`
  min-height: 350px;
  height: 50vh;

  background-size: cover;
  -o-background-size: cover;
  -moz-background-size: cover;
  background-position: center center;
`;

export const SMiniHeroH1 = styled.h1`
  color: #fff;
  text-transform: uppercase;
`;

export const SParallax = styled.div`
  height: 450px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
`;