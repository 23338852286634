import React from 'react';
import { useTranslation } from "react-i18next";

import Head from '../layouts/head';
import Header from '../components/header';
import Footer from '../components/footer';
import { SMiniHero } from '../styles/heroStyle';
import { SDivider10, SDivider50, SDivider100, SDivider20 } from '../styles/dividerStyles';

import { BsPhone } from "react-icons/bs";
import { MdOutlineEmail } from "react-icons/md";
import { GoLocation } from "react-icons/go";

const hero_img = 'url(/assets/img/villa/esterno_2.webp)';

export default function Contact() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('contact-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('contact-description', { phoneNumber: `${process.env.REACT_APP_CONTACT_PHONE}`, brand: `${process.env.REACT_APP_APP_NAME}` })}
        keyword={t('contact-keyword')}
        path='contact'
      />
      <Header/>
      <Hero/>
      <SDivider50/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Hero = () => {
  return (
    <SMiniHero style={{backgroundImage: hero_img}}>
    </SMiniHero>
  )
}

const Main = () => {
  const { t } = useTranslation();

  return(
    <main className='container'>
      <div className='row'>
        <div className='col-md-6 col-sm-12'>
          <h1>{t('contact-main-1')}</h1>
          <SDivider10/>

          <p>
            {t('contact-main-2')}
            <br/><br/>
            {t('contact-main-3')}
          </p>

          <SDivider20/>

          <BsPhone style={{fontSize: "20px", marginRight: "8px"}}/>
          <a href={`tel:` + process.env.REACT_APP_CONTACT_PHONE}>
            {process.env.REACT_APP_CONTACT_PHONE}
          </a>
          <SDivider20/>

          <MdOutlineEmail style={{fontSize: "20px", marginRight: "8px"}}/>
          <a href={`tel:` + process.env.REACT_APP_CONTACT_EMAIL}>
            {process.env.REACT_APP_CONTACT_EMAIL}
          </a>
          <SDivider20/>

          <MdOutlineEmail style={{fontSize: "20px", marginRight: "8px"}}/>
          <a href={`mailto:kritinam316@gmail.com`}>
            kritinam316@gmail.com
          </a>
          <SDivider20/>

          <GoLocation style={{fontSize: "20px", marginRight: "8px"}}/>
          <a href={process.env.REACT_APP_GOOGLE_MAPS} target="_blank" rel="noopener noreferrer">
           {process.env.REACT_APP_CITY_ADDRESS}, {process.env.REACT_APP_ZIP} {process.env.REACT_APP_CITY} ({process.env.REACT_APP_STATE})
          </a>
        </div>

        <div className='col-md-6 col-sm-12'>
          <iframe title={`Location ${process.env.REACT_APP_APP_NAME} on google maps`} src={process.env.REACT_APP_WIDGET_GOOGLE_MAPS} style={{border: "0", minHeight: "300px", height: "100%", width: "100%"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </main>
  );
}