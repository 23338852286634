import React from 'react';
import { useTranslation } from "react-i18next";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';
import {
  SHero,
  SGridGallery,
  SGalleryImg
} from './styles';
import { STable, STheadTh, STbodyTd } from '../../styles/tableStyles';
import { SHrAuto } from '../../styles/hrStyles'
import { SDivider10, SDivider100, SDivider20, SDivider30, SDivider50 } from '../../styles/dividerStyles';
import { GoLocation } from "react-icons/go";

const carouselImg1 = 'url(/assets/img/villa/esterno_1.webp)';
const carouselImg2 = 'url(/assets/img/villa/esterno_2.webp)';
const carouselImg3 = 'url(/assets/img/villa/esterno_3.webp)';
const carouselImg4 = 'url(/assets/img/villa/comune_2.webp)';
const carouselImg5 = 'url(/assets/img/villa/comune_5.webp)';
const carouselImg6 = 'url(/assets/img/villa/comune_3.webp)';

const galleryImg1 = 'url(/assets/img/villa/esterno_1.webp)';
const galleryImg2 = 'url(/assets/img/villa/esterno_2.webp)';
const galleryImg3 = 'url(/assets/img/villa/esterno_3.webp)';
const galleryImg4 = 'url(/assets/img/villa/esterno_4.webp)';
const galleryImg5 = 'url(/assets/img/villa/comune_1.webp)';
const galleryImg6 = 'url(/assets/img/villa/comune_2.webp)';
const galleryImg7 = 'url(/assets/img/villa/comune_3.webp)';
const galleryImg8 = 'url(/assets/img/villa/comune_4.webp)';
const galleryImg9 = 'url(/assets/img/villa/comune_5.webp)';
const galleryImg10 = 'url(/assets/img/villa/comune_6.webp)';
const galleryImg11 = 'url(/assets/img/villa/room_1.webp)';
const galleryImg12 = 'url(/assets/img/villa/room_2.webp)';
const galleryImg13 = 'url(/assets/img/villa/room_3.webp)';
const galleryImg14 = 'url(/assets/img/villa/bagno_1.webp)';
const galleryImg15 = 'url(/assets/img/villa/bagno_2.webp)';

export default function Villa() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('villa-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('villa-description')}
        keyword={t('villa-keyword')}
        path='villa'
      />
      <Header/>
      <Hero/>
      <SDivider30/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Hero = () => {
  return (
    <Carousel infiniteLoop={true} showIndicators={true} showThumbs={false} showStatus={false}>
      <div>
        <SHero style={{backgroundImage: `${carouselImg1}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg2}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg3}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg4}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg5}`}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: `${carouselImg6}`}}/>
      </div>
    </Carousel>
  )
}

const Main = () => {
  return (
    <main>
      <Intro/>
      <Divider/>
      <Info/>
      <Divider/>
      <Geolocation/>
      <Divider/>{/*
      <Price/>
      <Divider/> */}
      <Gallery/>
    </main>
  )
}

const Intro = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-center">
      <h1>{t('villa-intro-1')}</h1>
      <SDivider20/>
      <p>
        {t('villa-intro-2')}
        <br/>
        <br/>
        {t('villa-intro-3')}
        <br/>
        <br/>
        {t('villa-intro-4')}
      </p>
    </div>
  )
}

const Info = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="container text-center">
        <h2 className="text-center">{t('info')}</h2>
        <SDivider20/>

        <div className='row'>
          <div className='col-md-4 col-sm-12'>
            <h3>{t('housing')}</h3>
            <SDivider10/>
            {t('villa-housing-2')}
            <SDivider10/>
            {t('villa-housing-3')}
            <SDivider10/>
            {t('villa-housing-4')}
            <SDivider10/>
            {t('villa-housing-5')}
            <SDivider10/>
            {t('villa-housing-6')}
          </div>

          <div className='col-md-4 col-sm-12'>
            <h3>{t('accessories')}</h3>
            <SDivider10/>
            {t('villa-accessories-2')}
            <SDivider10/>
            {t('villa-accessories-3')}
            <SDivider10/>
            {t('villa-accessories-4')}
            <SDivider10/>
            {t('villa-accessories-5')}
          </div>

          <div className='col-md-4 col-sm-12'>
            <h3>{t('rules')}</h3>
            <SDivider10/>
            {t('villa-rules-2')}
          </div>
        </div>
      </div>
    </div>
  )
}

const Geolocation = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-center">
      <h2>{t('geolocation')}</h2>
      <SDivider20/>

      <strong>
        <GoLocation style={{fontSize: "20px", marginRight: "8px"}}/>
        <a href={process.env.REACT_APP_GOOGLE_MAPS} target="_blank" rel="noopener noreferrer">
          {process.env.REACT_APP_CITY_ADDRESS}, {process.env.REACT_APP_ZIP} {process.env.REACT_APP_CITY} ({process.env.REACT_APP_STATE})
        </a>
      </strong>
      <SDivider30/>

      <iframe title={`Location ${process.env.REACT_APP_APP_NAME} on google maps`} src={process.env.REACT_APP_WIDGET_GOOGLE_MAPS} style={{border: "0", minHeight: "400px", height: "100%", width: "100%"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"/>
    </div>
  )
}

/* const Price = () => {
  const { t } = useTranslation();

  return (
    <div className='container'>
      <h3 className='text-center'>{t('price')}</h3>
      <SDivider10/>
      <p className='text-center'>{t('villa-price-2')}</p>
      <SDivider20/>

      <div style={{overflowX: "auto"}}>
        <STable>
          <thead>
            <tr>
              <STheadTh style={{minWidth: "170px"}}></STheadTh>
              <STheadTh style={{minWidth: "110px"}}>{t('villa-price-th-1')}</STheadTh>
              <STheadTh style={{minWidth: "110px"}}>{t('villa-price-th-2')}</STheadTh>
              <STheadTh style={{minWidth: "110px"}}>{t('villa-price-th-3')}</STheadTh>
              <STheadTh style={{minWidth: "110px"}}>{t('villa-price-th-4')}</STheadTh>
            </tr>
          </thead>

          <tbody>
            <tr>
              <STbodyTd>{t('villa-price-tr-1-1')}</STbodyTd>
              <STbodyTd>73 €</STbodyTd>
              <STbodyTd>69 €</STbodyTd>
              <STbodyTd>38 €</STbodyTd>
              <STbodyTd>24 €</STbodyTd>
            </tr>

            <tr>
              <STbodyTd>{t('villa-price-tr-2-1')}</STbodyTd>
              <STbodyTd>10 €</STbodyTd>
              <STbodyTd>6 €</STbodyTd>
              <STbodyTd>17 €</STbodyTd>
              <STbodyTd>16 €</STbodyTd>
            </tr>

            <tr>
              <STbodyTd>{t('villa-price-tr-3-1')}</STbodyTd>
              <STbodyTd>83 €</STbodyTd>
              <STbodyTd>75 €</STbodyTd>
              <STbodyTd>55 €</STbodyTd>
              <STbodyTd>40 €</STbodyTd>
            </tr>
          </tbody>
        </STable>
      </div>
    </div>
  )
} */

const Gallery = () => {
  return (
    <SGridGallery>
      <SGalleryImg style={{backgroundImage: `${galleryImg1}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg2}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg3}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg4}`}}/>

      <SGalleryImg style={{backgroundImage: `${galleryImg5}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg6}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg7}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg8}`}}/>


      <SGalleryImg style={{backgroundImage: `${galleryImg9}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg10}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg11}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg12}`}}/>

      <SGalleryImg style={{backgroundImage: `${galleryImg13}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg14}`}}/>
      <SGalleryImg style={{backgroundImage: `${galleryImg15}`}}/>
    </SGridGallery>
  )
}

const Divider = () => {
  return (
    <>
      <SDivider50/>
      <SHrAuto/>
      <SDivider50/>
    </>
  )
}