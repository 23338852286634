import React, { useState } from 'react';
import { useTranslation } from "react-i18next";

import {
  SHeaderBar,
  SAreaLogo,
  SLogo,
  SLogoImg,
  SMenuLangButton,
  SNavBar,
  SFaBar,
  SNavMenuLi,
  SNavMenuA
} from "./styles";

import logo from '../../assets/img/logo.png';
import { FaBars } from "react-icons/fa";

const languages = [
  {
    code: 'en',
    name: 'English',
    flag: "/assets/img/flag/uk.svg"
  },
  {
    code: 'it',
    name: 'Italiano',
    flag: "/assets/img/flag/it.svg"
  },
  {
    code: 'es',
    name: 'Español',
    flag: "/assets/img/flag/es.svg"
  },
]

export default function Header() {
  const [ menuActive, setMenuActive ] = useState(false);

  return (
    <SHeaderBar>
      <SAreaLogo>
        <Logo/>
        <MenuLang/>
      </SAreaLogo>

      <SNavBar $isopen={menuActive}>
        <NavbarMenu/>
      </SNavBar>

      <SFaBar onClick={() => setMenuActive(!menuActive)}>
        <FaBars/>
      </SFaBar>
    </SHeaderBar>
  );
}

const Logo = () => {
  const { t } = useTranslation();

  return (
    <SLogo to={t('url-home')}>
      <SLogoImg src={logo} alt="logo"/>
    </SLogo>
  );
}

function MenuLang() {
  function handleChangeLanguage(newCodeLang){
    window.location.assign("/" + newCodeLang + "/");
  }

  return (
    languages.map(({ code, name, flag }) => (
      <SMenuLangButton key={code} onClick={() => handleChangeLanguage(code)}>
        <img src={flag} height="35" alt={name}/>
      </SMenuLangButton>
    ))
  );
}

const NavbarMenu = () => {
  const { t } = useTranslation();

  return(
    <>
      <SNavMenuLi>
        <SNavMenuA to={t('url-villa')}>{t('header-villa')}</SNavMenuA>
      </SNavMenuLi>
      <SNavMenuLi>
        <SNavMenuA to={t('url-apartment')}>{t('header-stazzone')}</SNavMenuA>
      </SNavMenuLi>
      <SNavMenuLi>
        <SNavMenuA to={t('url-new_york')}>{t('header-new_york')}</SNavMenuA>
      </SNavMenuLi>
      <SNavMenuLi>
        <SNavMenuA to={t('url-contact')}>{t('header-contact')}</SNavMenuA>
      </SNavMenuLi>
    </>
  );
}