import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Head from '../../layouts/head';
import Header from '../../components/header';
import Footer from '../../components/footer';
import {
  SHero,
  SH1,
  SSubTitle,
  SH2,
  SGridCard, SCard, SCardTitle, SCardBody,
  SParallax,
  SSideContainerMd, SSideImgMd, SSideMd
} from './styles';
import { SDivider20, SDivider30, SDivider40, SDivider100, SDivider50, SDivider10 } from '../../styles/dividerStyles';
import { PiArrowFatLinesRightBold } from "react-icons/pi";

const bannerBackgroundColor = `rgb(42, 82, 190)`;
const carouselImg1 = 'url(/assets/img/villa/esterno_1.webp)';
const carouselImg2 = 'url(/assets/img/villa/esterno_2.webp)';
const carouselImg3 = 'url(/assets/img/villa/esterno_3.webp)';
const carouselImg4 = 'url(/assets/img/villa/esterno_4.webp)';
const parallax1 = 'url(/assets/img/villa/esterno_4.webp)';
const parallax2 = 'url(/assets/img/villa/esterno_3.webp)';
const bannerImgVilla = 'url(/assets/img/villa/esterno_1.webp)';
const bannerImgStazzone = 'url(/assets/img/stazzone/room_1.webp)';
const bannerImgNewYork = 'url(/assets/img/new_york/apartment_3/apartment_3-4.webp)';

export default function Home() {
  const { t } = useTranslation();

  return (
    <>
      <Head
        title={t('home-title', { brand: `${process.env.REACT_APP_APP_NAME}` })}
        description={t('home-description')}
        keyword={t('home-keyword')}
        path='home'
      />
      <Header/>
      <Hero/>
      <SDivider50/>
      <Main/>
      <SDivider100/>
      <Footer/>
    </>
  );
}

const Hero = () => {
  return (
    <Carousel infiniteLoop={true} showIndicators={false} showThumbs={false} showStatus={false}>
      <div>
        <SHero style={{backgroundImage: carouselImg1}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: carouselImg2}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: carouselImg3}}/>
      </div>
      <div>
        <SHero style={{backgroundImage: carouselImg4}}/>
      </div>
    </Carousel>
  )
}

const Main = () => {
  return (
    <main>
      <Welcome/>
      <SDivider100/>
      <SParallax style={{backgroundImage: parallax2}}/>
      <SDivider100/>
      <Apartments/>
      <SDivider100/>
      <Ecomerce/>
    </main>
  )
}

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-center">
      <SH1>{t('home-welcome-h1')}</SH1>
      <SDivider30/>
      <SSubTitle>{t('home-welcome-h1_sub')}</SSubTitle>
      <SDivider30/>

      <SH2>{t('home-welcome-2')}</SH2>
      <SDivider40/>

      <SGridCard>
        <SCard>
          <SCardTitle>{t('home-welcome-3')}</SCardTitle>
          <SCardBody>{t('home-welcome-4')}</SCardBody>
        </SCard>

        <SCard>
          <SCardTitle>{t('home-welcome-5')}</SCardTitle>
          <SCardBody>{t('home-welcome-6')}</SCardBody>
        </SCard>

        <SCard>
          <SCardTitle>{t('home-welcome-7')}</SCardTitle>
          <SCardBody>{t('home-welcome-8')}</SCardBody>
        </SCard>
      </SGridCard>

      <SDivider20/>
      <SParallax style={{backgroundImage: parallax1}}/>
      <SDivider30/>

      <h3>{t('home-welcome-10')}</h3>
      <p>
        {t('home-welcome-11')}<br/>
        {t('home-welcome-12')}<br/>
        {t('home-welcome-13')}
      </p>

      <SDivider30/>

      <SCard>
        <SCardTitle>{t('home-welcome-slogan_2')}</SCardTitle>
      </SCard>
    </div>
  )
}

const Apartments = () => {
  const { t } = useTranslation();

  return (
    <div className="text-center">
      <BannerApartement
        img={bannerImgVilla}
        to={t('url-villa')}
        title={t('home-apartments-residence')}
        description={t('home-apartments-residence-1')}
      />

      <SDivider20/>

      <BannerApartement
        img={bannerImgStazzone}
        to={t('url-apartment')}
        title={t('home-apartments-sciacca')}
        description={t('home-apartments-sciacca-1')}
      />

      <SDivider20/>

      <BannerApartement
        img={bannerImgNewYork}
        to={t('url-new_york')}
        title={t('home-apartments-new_york')}
        description={t('home-apartments-new_york-1')}
      />
    </div>
  )
}

const Ecomerce = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-center">
      <h2>{t('home-ecommerce-1')}</h2>
      <SDivider20/>

      <p>
        {t('home-ecommerce-2')}<br/><br/>
        <a href={t('url-ecommerce')} target="_blank" rel="noopener noreferrer">{t('home-ecommerce-3')}</a>
      </p>

      <SDivider30/>
    </div>
  )
}

const BannerApartement = ({img, to, title, description}) => {
  return (
    <SSideContainerMd style={{background: bannerBackgroundColor}}>
      <SSideImgMd style={{backgroundImage: img}}/>

      <SSideMd style={{display: "table"}}>
        <Link to={to} style={{padding: "20px", display: "table-cell", verticalAlign: "middle"}}>
          <div style={{ border: "2px solid #fff", width: "100%", height: "100%", padding: "15px", display: "table" }}>
            <div style={{display: "table-cell", verticalAlign: "middle", color: "#fff"}}>
              <h4 style={{color: "#fff"}}>{title}</h4>

              <SDivider10/>

              <p>{description}</p>

              <SDivider10/>

              <PiArrowFatLinesRightBold style={{fontSize: "30px"}}/>
            </div>
          </div>
        </Link>
      </SSideMd>
    </SSideContainerMd>
  )
}